import { render, staticRenderFns } from "./CargaArchivosTab.vue?vue&type=template&id=6285bc05&scoped=true&"
import script from "./CargaArchivosTab.vue?vue&type=script&lang=js&"
export * from "./CargaArchivosTab.vue?vue&type=script&lang=js&"
import style0 from "./CargaArchivosTab.vue?vue&type=style&index=0&id=6285bc05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6285bc05",
  null
  
)

export default component.exports